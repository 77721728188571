<template>
  <div class="root_">
    <!-- left side image -->
    <div class="left_">
      <div class="pic">
        <!-- image url part! -->
        <img v-bind:src="imageUrl" class="small-img" crossorigin="anonymous" />
      </div>

    </div>
    <div class="right_">
      <!-- buttons -->
      <div class="top">
        <div class="buttons">
          <b-button class="shippingcostcalbtn" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onClick('shipping-cost-calculator')">
            Shipping Cost Calculator
          </b-button>
          <b-button class="bttn3" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onClick('login')">
            Login
          </b-button>
        </div>
      </div>
      <!-- right side text -->
      <div class="bottom">
        <div class="title_">{{ title }}</div>
        <div class="text_">
          {{ description }}
        </div>
        <div class="bttns">
          <!-- <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onClick('signup')">
            SIGN-UP
          </b-button>
          <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onClick('login')">
            LOGIN
          </b-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  props: ["content"],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.content !== null) return this.content.title;
      return "";
    },
    description() {
      if (this.content !== null) return this.content.description;
      return "";
    },
    rating() {
      if (this.content !== null) return this.content.rating;
      return "";
    },
    imageUrl() {
      if (this.content !== null) return this.content.imageUrl;
      return "";
    },
  },
  methods: {
    onClick(name) {
      if (name === "login") this.$router.push({ name: "login" });
      else if (name === "signup") this.$router.push({ name: "register-demo" });
      else if (name === "shipping-cost-calculator")
        this.$router.push({ name: "shipping-cost-calculator" });
      else if (name === 'howyicworks')
        this.$router.push({ name });
    },
  },
};
</script>
<style scoped>
.root_ {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  height: 76vh;
}

.small-img {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}

.title_ {
  margin-top: 5rem;
  font-weight: 600;
  font-size: 58px;
  line-height: 80px;
  color: #414141;
  max-width: 600px;
}

.text_ {
  margin-top: 2rem;
  max-width: 600px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #656565;
}

.left_ {
  /* background: #4385f5; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .pic { */
  /* width: 557px; */
  /* height: 592px; */
  /* background-image: url("../../../assets/yicimages/homePage/landPage.png"); */
/* } */

.bttns {
  width: 600px;
  margin-top: 6rem;
}

.bttn1 {
  background: #e87613;
  color: #ffffff;
  width: 185px;
}

.bttn2 {
  margin-right: 1.5rem;
  background: #4385f5;
  color: #ffffff;
  width: 185px;
  height: 40px;
}

.bttn3 {
  max-width: 255px;
  height: 40px;
  border: 1px solid #4385f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4385f5;
  display: flex;
  align-items: center;
  background: white;
}
.shippingcostcalbtn{
  max-width: 255px;
  height: 40px;
  border: 1px solid #4385f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  background: #4385f5;
}

.buttons {
  display: flex;
  margin-top: 1rem;
  gap: 1.5rem;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10rem;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/*========================= Media Queries (Meduim devices) =========================*/
/* @media screen and (max-width: 1200px) {
  .root_ {
    grid-template-columns: 1fr;
    height: 100%;
    width: 100vw;
  }
  .pic {
  width: 200px;
  height: 200px;
  }
} */
/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 1200px) {
  .root_ {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
  }

  .title_ {
    font-size: 30px;
    max-width: 550px;
  }

  .text_ {
    max-width: 550px;
    font-size: 20px;
    line-height: none;
    color: #656565;
    margin-left: 1rem;
    text-align: center;
  }

  .pic {
    width: 100vw;
    height: 30vh;
  }

  .bttns,
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100vw;
  }

  .bttn3 {
    font-size: 11px;
  }

  .bottom {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .title_ {
    margin-top: 1rem;
    justify-content: center;
    font-size: 20px;
    line-height: 40px;
  }

  .text_ {
    margin-top: 0rem;
    font-size: 18px;
  }

  .bttns {
    margin-top: 0;
  }
}
</style>