<template>
  <div class="root_home dots">
    <!-- nabar -->
    <Navbar value='0'/>

    <div v-if="active !== 1">
      <!-- buttons -->
      <div class="buttons" v-if="active === 1">
        <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="onClick('shipping-cost-calculator')">
          Shipping Cost Calculator
          <!-- {{ active }} -->
        </b-button>
        <b-button class="bttn1" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
          My Indian Virtual Address
        </b-button>
      </div>

      <!-- carousel -->
      <!-- <div class="carousel">
        <AfterRegisterationCarouselComponent />
      </div> -->
    </div>

    <!-- Landing Page -->
    <div v-else class="land">
      <BeforeRegisterationCarouselComp />
    </div>

    <!-- boxes -->
    <div :class="[ active == 2 ? 'grey3' : 'grey2' ]">
      <div v-if="active !== 3" class="boxes-title">
        <span v-if="active === 2">What’s Next?</span>
        <span v-else>Why choose YourIndianCart?</span>
      </div>
      <div class="outer">
        <div class="one_box" v-for="i in boxes">
          <img class="box-img" :src="i.src" />
          <div class="text">{{ i.text }}</div>
        </div>
      </div>
    </div>

    <!-- Helper Boxes -->
    <div v-if="active === 2" class="helper_box">
      <Helpers title="Calculate your shipping cost"
        p="Find the perfect plan for your needs, discover how we can help you save, or "
        blue="calculate your shipping costs" bttn="CALCULATE NOW" lnk="shipping-cost-calculator" />
    </div>

    <!-- slide 3 -->
    <div class="slide3">
      <div class="left">
        <div class="title">Shop from your favourite stores</div>
        <div class="p">
          Browse through popular stores, take your pick, shop &amp; get it
          delivered to your virtual address;
          <span class="blue"> we'll ship it to your destination abroad! </span>
        </div>
        <!-- <b-button
          class="bttn2"
          variant="no-color"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        >
          START BROWSING
        </b-button> -->
      </div>
      <div class="right">
        <div class="right-img"></div>
      </div>
    </div>

    <!-- helpers box 2 -->
    <div v-if="active === 2" class="helper_box">
      <Helpers title="Fill in Sender’s Information"
        p="As per the government regulations, Aadhar Card Copy or Passport Copy is a document which is needed to be submitted so the package is sent to the delivery address. Please complete the sender information which is a one time setup and will be used for all shipments going forward. If a new sender will need to be used then upload the new photo ID documents."
        bttn="FILL NOW" lnk="account-settings" qry="" />
    </div>

    <!-- testimonials -->
    <div v-if="active === 1" class="tmlx">
      <CarouselCompTestimonials />
    </div>

    <!-- footer -->
    <FooterYic />
  </div>
</template>
<script>
import Navbar from "../navbar/Navbar.vue";
import FooterYic from './footer.vue'
import BeforeRegisterationCarouselComp from "./BannerCarousel/BeforeRegisterationCarouselComp.vue";
import AfterRegisterationCarouselComponent from "./BannerCarousel/AfterRegisterationCarouselComponent.vue";
import CarouselCompTestimonials from "./Testimonials/CarouselCompTestimonials.vue";
import Helpers from "./Helpers.vue";

import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import CarouselSlide from "./BannerCarousel/AfterRegisterationCarouselSlide.vue";
import pic1 from '../../assets/yicimages/whyYIC/1.png'
import pic2 from '../../assets/yicimages/whyYIC/2.png'
import pic3 from '../../assets/yicimages/whyYIC/3.png'
import pic4 from '../../assets/yicimages/whyYIC/4.png'
import pic5 from '../../assets/yicimages/whyYIC/5.png'
import pic6 from '../../assets/yicimages/whyYIC/6.png'
import pic7 from '../../assets/yicimages/whyYIC/7.png'
import pic8 from '../../assets/yicimages/whyYIC/8.png'
export default {
  components: {
    CarouselCompTestimonials,
    AfterRegisterationCarouselComponent,
    BeforeRegisterationCarouselComp,
    CarouselSlide,
    Helpers,
    FooterYic,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // active: 1,
      /*
      active
      1 -> beforeRegisteration / Landing Page
      2 -> When the user first signs up! or first time logs in
      3 -> When the user logs in (but whats next part is not shown!)
      */
      // boxes: [1, 2, 3, 4],
      //by default 3 rd page features are shown!
      boxes: [
        { src: pic1, text: 'Sign up and get your own virtual address for free' },
        { src: pic2, text: 'Shop from favourite Indian store and we deliver them' },
        { src: pic4, text: 'Consolidate multiple packages into one,to save shipping cost.' },
        { src: pic7, text: 'Live package tracking and timely updates of your packages ' },
        { src: pic5, text: 'Door to door pickup and delivery ' },
        { src: pic3, text: 'Repacked by our professionals for damage free shipping ' },
        { src: pic8, text: 'Easy and hassle free returns ' },
        { src: pic6, text: '24*7 customer support ' },
      ],
    };
  },
  computed: mapState({
    active: (state) => {
      // loged in -> 2,3
      // not loged in -> 1
      if (!state.login.isAuthenticated) return 1;
      else return 2;
    },
  }),
  // mounted() {
  //   if (this.active == 3) {
  //     this.boxes = [1, 2, 3, 4];
  //   } else {
  //     this.boxes = [1, 2, 3, 4, 5, 6];
  //   }
  // },
  methods: {
    onClick(name) {
      if (name === "shipping-cost-calculator")
        this.$router.push({ name: "shipping-cost-calculator" });
    },
  },
};
</script>
<style scoped>
.root_home {
  background: #ffffff;
}

.bttn1 {
  width: 255px;
  height: 40px;
  border: 1px solid #4385f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4385f5;
  display: flex;
  align-items: center;
  background: white;
}

.bttn2 {
  background: #4385f5;
  color: #ffffff;
  max-width: 225px;
}

.buttons {
  display: flex;
  margin-top: 1rem;
  gap: 1.5rem;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10rem;
}

.blue {
  color: #4385f5;
}

.boxes {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.box {
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.box_wrap {
  background: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #4385f5;
}

.box-img {
  height: 80%;
  width: 100%;
  object-fit: contain;
}

.title {
  font-weight: 600;
  font-size: 38px;
  color: #414141;
  width: 450px;
}

.p {
  width: 400px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 37px;
  /* or 154% */
}

.slide3 {
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 8rem;
  height: 80vh;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.9rem;
  padding-left: 8rem;
  padding-right: 0;
}

.tmlx {
  background: #f9f9f9;
  padding-bottom: 2rem;
}

.text {
  font-size: 15px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  width: 100%;
  color: black;
  text-align: center;
  /* width: 450px; */
}

.carousel {
  margin-top: 3rem;
  height: 80vh;
}

.dots {
  background-image: url("../../assets/yicimages/homePage/dots.png");
}

.grey {
  height: 357px;
  background: #414141;
}

.boxes3 {
  margin-top: 3rem;
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* padding-left: 8rem;
  padding-right: 8rem; */

}

/* .box3 {
  flex: 0 0 25%;
  height: 180px;
  width: 180px;
} */

.boxes-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 80px;
  text-align: center;
  color: #414141;
  margin-top: 0;
  padding-top: 5rem;
}
.grey3{
  background: #f9f9f9;
  width: 100%;
  height: max-content;
  padding: 0
}

.grey2 {
  background: #f9f9f9;
  width: 100%;
  height: max-content;
  padding: 3rem 10rem;

}

.helper_box {
  margin: 10rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land {
  margin-bottom: 2rem;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-img {
  background-image: url("../../assets/yicimages/homePage/brands.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 80%;
}

.outer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 1rem;
}

.one_box {
  height: 270px;
  width: 300px;
  padding: 1rem;
  /* border: 1px solid black; */
  margin: auto;
  background: #ffffff;

}

/*========================= Media Queries (Meduim devices) =========================*/

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 1200px) {
  .root_home {
    background: #ffffff;
    height: 100%;
  }

  .buttons {
    display: flex;
    /* margin-top: 1rem; */
    gap: 0.5rem;
    width: 100%;
    justify-content: center;
    padding-right: 0;
  }

  .carousel {
    margin-top: 1rem;
    height: fit-content;
    padding: 0rem 1rem 1rem 1rem;
  }

  .boxes-title {
    font-size: 1.5rem;
    line-height: 80px;
    text-align: center;
    color: #414141;
    padding-top: 0rem;
  }

  .boxes3 {
    margin-top: 0rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* padding-left: 5rem; */
    /* padding-right: 5rem; */
    gap: 1rem;
  }

  .boxes {
    margin-top: 0;
    gap: 1rem;
  }

  /* .box {
    width: 150px;
    height: 100px;
  } */

  .grey2 {
    height: fit-content;
    padding: 3rem 0;

  }

  .helper_box {
    margin: 0rem 1rem 1rem 1rem;
  }

  .slide3 {
    width: 92%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem 0rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  .left {
    width: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    height: fit-content;
  }

  .right {
    width: 100%;
    height: 60%;
  }

  .title {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    font-size: 1.5rem;
  }

  .p {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
  }

  .outer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 0.3rem;
  }

  .one_box {
    height: 300px;
    width: 100%;
    padding: 1rem;
    /* border: 1px solid black; */
    margin: auto;
    background: #ffffff;

  }

  .box-img {
    height: 70%;
    width: 100%;
    margin-bottom: 0.2rem;
    object-fit: contain;
  }
}
</style>
