<template>
  <carousel
    :perPageCustom="[
      [480, 1],
      [768, 1],
    ]"
  >
    <slide v-for="(obj, indx) in testimonials" :key="indx">
      <Testimonials :content="obj" />
    </slide>
  </carousel>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapMutations, mapActions, mapState } from "vuex";
import Testimonials from "./SingleTestimonial.vue";
export default {
  components: {
    Testimonials,
    Carousel,
    Slide,
  },

  methods: {
    ...mapActions({
      testimonialsData: "testimonials/extractTestimonials",
    }),
  },

  computed: {
    ...mapState({
      testimonials: (state) => {
        // console.log(state.testimonials.testimonials);
        return state.testimonials.testimonials;
      },
    }),
  },
  async mounted() {
    await this.testimonialsData();
  },
};
</script>