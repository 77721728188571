<template>
  <!-- testimonials -->
  <div class="tml">
    <div class="left2">
      <div class="title2">{{ title }}</div>
      <div class="rating">
        <div class="star" v-for="n in rating" :key="n"></div>
        <div class="star1" v-for="e in 5 - rating" :key="`${e}x`"></div>
        <span class="text_"> {{ rating }}/5 </span>
      </div>
      <div class="text">{{ description }}</div>
    </div>
    <div class="right2 girl">
      <img v-bind:src="imageUrl" class="small-img" crossorigin="anonymous" />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  props: ["content"],
  //conetnt is an object containg four key-value pairs
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    title() {
      if (this.content !== null) return this.content.title;
      return "";
    },
    description() {
      if (this.content !== null) return this.content.description;
      return "";
    },
    rating() {
      if (this.content !== null) return this.content.rating;
      return "";
    },
    imageUrl() {
      if (this.content !== null) return this.content.imageUrl;
      return "";
    },
  },
};
</script>
<style scoped>
.girl {
  background-image: url("../../../assets/yicimages/homePage/girl.png");
}
.small-img {
  height: 100%;
  width: 100%;
}
.star {
  width: 22px;
  height: 22px;
  background-image: url("../../../assets/yicimages/homePage/fillStar.png");
}
.star1 {
  width: 22px;
  height: 22px;
  background-image: url("../../../assets/yicimages/homePage/emptyStar.png");
}
.rating {
  display: flex;
  gap: 0.8rem;
}
.left2 {
  padding: 2.5rem 3rem;
  padding-left: 20rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}
.tml {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 2rem 0;
}
.title2 {
  width: 450px;
  font-weight: 600;
  font-size: 38px;
  color: #414141;
}
.text {
  font-size: 18px;
  color: #797979;
  width: 450px;
}
.right2 {
  width: 349px;
  height: 370px;
}
.text_ {
  font-weight: 600;
  font-size: 18px;
  color: #414141;
}
@media screen and (max-width: 1200px){
  .tml{
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0.5rem;
  }
  .left2{
    padding: 1rem;
    gap: 1rem;
    width: 95vw;
  }
  .title2{
    width: fit-content;
    font-size: 30px;
  }
  .text{
    width: fit-content;
    font-size: 15px;
  }
  .right2{
    width: 90vw;
    height: 30vh;
    padding: 0.5rem;
  }
}
</style>

