<template>
  <carousel
    v-bind:autoplay="true" v-bind:loop="true" v-bind:autoplayTimeout="3000" v-bind:autoplayHoverPause="false"
    :perPageCustom="[
      [480, 1],
      [768, 1],
    ]"
  >
    <slide v-for="(obj, indx) in bannerContent" :key="indx">
      
      <AfterRegisterationCarouselSlide :content="obj" />
    </slide>
  </carousel>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import AfterRegisterationCarouselSlide from "./AfterRegisterationCarouselSlide.vue";
export default {
  components: {
    AfterRegisterationCarouselSlide,
    Carousel,
    Slide,
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions({
      extractData: "homePageCarousel/extractContent",
    }),
  },

  computed: {
    ...mapState({
      bannerContent: (state) => {
        return state.homePageCarousel.bannerContent;
      },
    }),
  },
  async mounted() {
    await this.extractData();
  },
};
</script>