<template>
  <div class="root_2">
    <div class="title__">{{ title }}</div>
    <div class="p__">
      {{ p }}
      <span class="blue_">{{ blue }} </span>
    </div>
    <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onClick(lnk)">
      {{ bttn }}
    </b-button>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["title", "p", "blue", "bttn", "lnk", "qry"],
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  methods: {
    onClick(nam) {
      const obj = { name: nam };
      if (this.qry) {
        obj.query = { openModal: true }
      }
      this.$router.push(obj);
    },
  },
  mounted() { },
};
</script>
<style scoped>
.root_2 {
  box-sizing: border-box;
  width: 1110px;
  height: 312px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0 5rem;
}

.p__ {
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;

  text-align: center;
  color: #797979;
}

.blue_ {
  color: #4385f5;
}

.bttn2 {
  background: #e87613;
  color: #ffffff;
  max-width: 225px;
  margin-top: 3rem;
  height: 50px;
}

.title__ {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 36px;
  line-height: 80px;
  color: #414141;
}

@media screen and (max-width: 1200px){
  .title__{
    margin-top: 1rem;
    font-size: 1.5rem;
    line-height: 30px;
  }
  .root_2{
    height: fit-content;
    padding: 1rem 0rem 1rem 0rem;
  }
  .p__{
    font-size: 1rem;
  }
  .bttn2{
    margin-top: 1rem;
  }
}
</style>