<template>
  <div class="root_">
    <div class="left_">
      <div class="title_">
        {{ title }}
        <!-- <span class="yellow"> doorstep.</span> -->
      </div>
      <div class="text_">
        {{ description }}
      </div>
      <!-- <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
        EXPLORE
      </b-button> -->
    </div>
    <div class="right_">
      <!-- {{imageUrl}} -->
      <img v-bind:src="imageUrl" crossorigin="anonymous" />
      <!-- image Url -->
    </div>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  props: ["content"],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.content !== null) return this.content.title;
      return "";
    },
    description() {
      if (this.content !== null) return this.content.description;
      return "";
    },
    rating() {
      if (this.content !== null) return this.content.rating;
      return "";
    },
    imageUrl() {
      if (this.content !== null) return this.content.imageUrl;
      return "";
    },
  },
};
</script>
<style scoped>
.root_ {
  padding: 0 8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
}

.bttn2 {
  background: #4385f5;
  color: #ffffff;
  max-width: 225px;
}

.left_ {
  padding: 2rem 4rem;
}

.title_ {
  font-weight: 600;
  font-size: 38px;
  color: #414141;
  width: 540px;
  margin-bottom: 1rem;
}

.yellow {
  color: #e87613;
}

.text_ {
  width: 450px;
  font-weight: 400;
  font-size: 18px;
  color: #797979;
  margin-bottom: 3rem;
}

.right_ {
  /* background-image: url("../../../assets/yicimages/homePage/carouselImage.png"); */
  width: 500px;
  height: 400px;
}

/*========================= Media Queries (Meduim devices) =========================*/
@media screen and (max-width: 1200px) {
  .root_ {
    display: flex;
    flex-direction: column;
    width: 93vw;
    height: 100%;
    padding: 0;
  }

  .right_ {
    display: visible;
    width: 100vw;
    height: 60vh;
  }

  .left_ {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }

  .title_ {
    width: 100vw;
    font-size: 28px;
  }

  .text_ {
    font-size: 18px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
    width: fit-content;
  }
}
</style>